import React, {useCallback, useEffect, useState} from 'react';
import './SendItem.css';
import {useTelegram} from "../../hooks/useTelegram";
import Combobox from "react-widgets/Combobox";
//import "react-widgets/styles.css";
import Button from "../Button/Button";

const SendItem = () => {

    const [characters, setCharacters] = useState([]);
    const [characterId, setCharacterId] = useState('');
    const [items, setItems] = useState([]);
    const [itemId, setItemId] = useState('');
    const [itemAmount, setItemAmount] = useState(1);
    const [isData, setIsData] = useState(false);
    const {tg} = useTelegram();

 
 const getApiData = async () => {


        let response = await fetch('https://tg2.hardlc.com/api/characters/list', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ botType: 0, initData: tg.initData })
        });

        let responseText = await response.text();
        let result = JSON.parse(responseText);

        setCharacters(result);
        console.log(result);

        response = await fetch('https://tg2.hardlc.com/api/data/item/list', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ botType: 0, initData: tg.initData })
        });

        responseText = await response.text();
        result = JSON.parse(responseText);

        setItems(result);
        console.log(result);

        setIsData(true);

        /*setPeople(result);
        setLogin(result[0]);
        setListDisable(false)
        console.log(result)*/
};

    useEffect(() => {
      getApiData();  
      
    }, []);

    const onChangeItemAmount = (e) => {
        setItemAmount(e.target.value)
    }

    return (
        <div className={"sendItem"}>
            <h3>Отправить предмет</h3>
            <br/>
            <center><img src="https://ru.hardlc.com/img/webp/logos/logo.webp" width="300" /></center>
            <br/>
            <label>Персонаж:</label>
            <Combobox
                data={characters}
                textField='name'
                filter='contains'
                disabled={!isData}
                onChange={value => setCharacterId(value.id)}
            />
            <br/>
            <label>Предмет:</label>
            <Combobox
                data={items}
                textField='name'
                filter='contains'
                disabled={!isData}
                onChange={value => setItemId(value.id)}
            />
            <br/>
            <label>Количество:</label>
            <input
                className={'input'}
                type="number"
                placeholder={'Количество'}
                value={itemAmount}
                onChange={onChangeItemAmount}
            />
            <br/>
            <Button className={'add-btn'}>
                Отправить
            </Button>
        </div>
    );
};

export default SendItem;
