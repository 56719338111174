import React, {useCallback, useEffect, useState, useRef} from 'react';
import './ProcessHash.css';
import {useTelegram} from "../../hooks/useTelegram";
import Combobox from "react-widgets/Combobox";
//import Button from "../Button/Button";
//import "react-widgets/styles.css";
import ReactSpoiler from "react-spoiler";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';


import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';


import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';

const ProcessHash = () => {
    const [message, setMessage] = useState('');


    const [country, setCountry] = useState('');
    const [street, setStreet] = useState(100);
    const [user, setUser] = useState('');
    const [people, setPeople] = useState([]);
    const [dataReceived, setDataReceived] = useState(true);
    const [subject, setSubject] = useState('physical');

    const [amount, setAmount] = useState('');
    const [info, setInfo] = useState('');


    const {tg} = useTelegram();

    

    



    useEffect(() => {
        getApiData2();
    }, [user])

    const getApiData2 = async () => {


        //setInfo(user.name)

        if (user.name == undefined) {
            return;
        }

        tg.MainButton.setParams({
            text: 'Ожидание получения данных...'
        })

        setDataReceived(false);
        tg.MainButton.show();
        tg.MainButton.showProgress();

       const data = {
            botType: 0,
            initData: tg.initData,
            login: user.name
        }

        const response = await fetch('https://tg2.hardlc.com/api/users/info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        /*let text = `ID: ${result.user_code}\n`;
        text += `Логин: ${result.user_id}\n`;
        text += `E-mail: ${result.email.trim()}\n`;
        text += `Шоп: ${result.cash}\n`;
        text += `Дата регистрации: ${result.create_date}\n`;
        text += `Локация: ${result.a_zone_num}\n`;*/

        let text = ``;

        for (const [key, value] of Object.entries(result)) {
            text += `${key}: ${value}\n`;
        }

        setInfo(text);

        setDataReceived(true);
        tg.MainButton.hide();
        tg.MainButton.hideProgress();

};


    const getApiData = async () => {


        if (people.length > 0) {
            return;
        }


        console.log(`getApiData`)




        const data = {
            botType: 0,
            initData: tg.initData,
        }
        const response = await fetch('https://tg2.hardlc.com/api/process_hash/list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        setPeople(result);
        setUser(result[0]);
        setDataReceived(true);
};

    useEffect(() => {
      getApiData();
    }, []);

    /*const Redirector = ({ url }) => {
  useEffect(() => { window.location.href = url; }, [url]);
  return null;
};
*/



    const onSendData = useCallback(async () => {
        const data = {
            botType: 0,
            initData: tg.initData,
            hash: amount
        }

        setMessage(``);

        const response = await fetch('https://tg2.hardlc.com/api/process_hash/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        setMessage(result.msg + `\n\n`);

    }, [user, amount])



    const onAddHandler = () => {
        onSendData();
    }

    const onCellEditComplete = (e) => {
        
    }

    const onChangeAmount = (e) => {
        setAmount(e.target.value)
    }







    

    const getSeverity = (product) => {
        return 'danger';

        /*switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }*/
    };

    const [visible, setVisible] = useState(false);

    const itemTemplate = (product, index) => {
        return (
            <div className="col-12" key={product.a_index}>
                <div className={classNames('flex flex-column xl:flex-row xl:align-items-start p-4 gap-4', { 'border-top-1 surface-border': index !== 0 })}>
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{product.a_hash}</div>
                        </div>
                        <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                            <Toast ref={toast} />
                            <ConfirmPopup />
                            <Button onClick={confirm2} icon="pi pi-times" label="Delete" className="p-button-danger"></Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((product, index) => {
            return itemTemplate(product, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };



    const toast = useRef(null);

    const accept = () => {
        toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm2 = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this record?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    return (
        <div className={"processHash"}>
            <h3>Запрещённые процессы</h3>
            <pre>
                {message}
            </pre>
            <br/>
            <input
                className={'input'}
                type="text"
                placeholder={''}
                disabled={!dataReceived}
                value={amount}
                onChange={onChangeAmount}
            />
            <br/>
            <Button onClick={onAddHandler}>
                Добавить
            </Button>
            
            <br/>
            <DataView value={people} listTemplate={listTemplate} />
            <br/>
            <DataTable value={people} scrollable scrollHeight="400px" style={{ maxWidth: '100%' }}>
                <Column field="a_hash" header="Name"></Column>
                <Column field="a_comment" header="Company"></Column>
            </DataTable>
        </div>
    );
};

export default ProcessHash;
