import React, {useCallback, useEffect, useState} from 'react';
import './Users.css';
import {useTelegram} from "../../hooks/useTelegram";
import Combobox from "react-widgets/Combobox";
import Button from "../Button/Button";
//import "react-widgets/styles.css";
import ReactSpoiler from "react-spoiler";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";

const Users = () => {
    const [country, setCountry] = useState('');
    const [street, setStreet] = useState(100);
    const [user, setUser] = useState('');
    const [people, setPeople] = useState([]);
    const [dataReceived, setDataReceived] = useState(true);
    const [subject, setSubject] = useState('physical');


    const [info, setInfo] = useState('');


    const {tg} = useTelegram();

    

    



    useEffect(() => {
        getApiData2();
    }, [user])

    const getApiData2 = async () => {


        //setInfo(user.name)

        if (user.name == undefined) {
            return;
        }

        tg.MainButton.setParams({
            text: 'Ожидание получения данных...'
        })

        setDataReceived(false);
        tg.MainButton.show();
        tg.MainButton.showProgress();

       const data = {
            botType: 0,
            initData: tg.initData,
            login: user.name
        }

        const response = await fetch('https://tg2.hardlc.com/api/users/info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        /*let text = `ID: ${result.user_code}\n`;
        text += `Логин: ${result.user_id}\n`;
        text += `E-mail: ${result.email.trim()}\n`;
        text += `Шоп: ${result.cash}\n`;
        text += `Дата регистрации: ${result.create_date}\n`;
        text += `Локация: ${result.a_zone_num}\n`;*/

        let text = ``;

        for (const [key, value] of Object.entries(result)) {
            text += `${key}: ${value}\n`;
        }

        setInfo(text);

        setDataReceived(true);
        tg.MainButton.hide();
        tg.MainButton.hideProgress();

};


    const getApiData = async () => {


        if (people.length > 0) {
            return;
        }


        console.log(`getApiData`)




        const data = {
            botType: 0,
            initData: tg.initData,
        }
        const response = await fetch('https://tg2.hardlc.com/api/users/list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        setPeople(result);
        setUser(result[0]);
        setDataReceived(false);
};

    useEffect(() => {
      getApiData();
    }, []);

    /*const Redirector = ({ url }) => {
  useEffect(() => { window.location.href = url; }, [url]);
  return null;
};
*/
    const onAddHandler = () => {
        window.location.href = `https://tg.hardlc.com/cash`; // id
    }

    return (
        <div className={"users"}>
            <h3>Информация об аккаунтах</h3>
            <br/>

            <Combobox
                data={people}
                textField='name'
                filter='contains'
                disabled={!dataReceived}
                //{dataReceived ? `busy hideCaret` : `` }
                value={user}
                onChange={value => setUser(value)}
            />
            <br/>
          
  <pre>
                
                    {info}
                
            </pre>


            <br/>
            <hr/>
            <br/>
            <Button onClick={onAddHandler}>
                Заблокировать
            </Button>
        </div>
    );
};

export default Users;
