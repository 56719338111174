import React, { useCallback, useEffect, useState } from 'react';
import Combobox from "react-widgets/Combobox";
import { useTelegram } from "../../hooks/useTelegram";
import './Cash.css';

const Cash = () => {
    const [message, setMessage] = useState('');
    const [amount, setAmount] = useState('');
    const [user, setUser] = useState('');
    const [users, setUsers] = useState([]);
    const [dataReceived, setDataReceived] = useState(false);
    const {tg} = useTelegram();

    const onSendData = useCallback(async () => {
        const data = {
            botType: 0,
            initData: tg.initData,
            login: user.name,
            amount: amount
        }

        setDataReceived(false);
        setMessage(``);
        tg.MainButton.showProgress();

        const response = await fetch('https://tg2.hardlc.com/api/users/cash/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        setDataReceived(true);
        setMessage(result.msg + `\n\n`);
        tg.MainButton.hideProgress();

    }, [user, amount])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Зачислить'
        })
    }, [])

    useEffect(() => {
        if (!user || amount < 1) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [user, amount])

    const getApiData = async () => {
        const data = {
            botType: 0,
            initData: tg.initData
        }
        const response = await fetch('https://tg2.hardlc.com/api/users/list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        const responseText = await response.text();
        const result = JSON.parse(responseText);

        setUsers(result);
        setUser(result[0]);
        setDataReceived(true);
    };

    useEffect(() => {
      getApiData();
    }, []);

    const onChangeAmount = (e) => {
        setAmount(e.target.value)
    }

    return (
        <div className={"cash"}>
            <pre>
                {message}
            </pre>
            <Combobox
                data={users}
                textField='name'
                filter='contains'
                disabled={!dataReceived}
                value={user}
                onChange={value => setUser(value)}
            />
            <input
                className={'input'}
                type="number"
                placeholder={'Сумма (₽)'}
                disabled={!dataReceived}
                value={amount}
                onChange={onChangeAmount}
            />
        </div>
    );
};

export default Cash;
